@import "./font.scss";
@import "./variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "./style";
@import "./header";
@import "./button";
@import "./banner";
@import "./restaurants";
@import "./loader";
@import "./icon";
