#header {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;
  height: 70px;
  display: flex;
  align-items: center;
  .logo {
    img {
      height: 45px;
    }
  }
}
