.RestaurantsMenuList {
}

.menu-list-row {
  @media (min-width: $tablet) {
    height: calc(100vh - 70px);
    overflow: auto;
  }

  .category-col {
    @media (min-width: $tablet) {
      top: 0;
      position: sticky;
      height: 100%;
    }
    .btn {
      box-shadow: none !important;
    }
  }
  .cart-col {
    @media (min-width: $tablet) {
      top: 0;
      position: sticky;
      height: 100%;
    }
  }
}
.MenuItemCard {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .detail {
    flex: 0 0 calc(100% - 128px);
    max-width: calc(100% - 128px);
    padding-right: 8px;
  }
  .items-to-cart {
    flex: 0 0 128px;
    max-width: 128px;
  }
  .type {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .v-o-nv {
      height: 14px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      margin-left: 4px;
      color: #ee9c00;
    }
  }
  .name {
    font-size: 1.22rem;
    font-weight: 500;
    color: #282c3f;
    margin-bottom: 0;
  }
  .price {
    margin-top: 8px;
    color: #282c3f;
    font-size: 14px;
    margin-bottom: 0;
  }
  .dec {
    margin-top: 8px;
    color: #686b78;
    margin-bottom: 0;
    font-size: 14px;
  }
  .items-to-cart {
    .image-box {
      background: #fbeed7;
      width: 128px;
      height: 108px;
      border-radius: 6px;
      position: relative;
      z-index: 1;
      img {
        position: absolute;
        border-radius: 6px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
      & + .handlerWrapper {
        margin-top: -18px;
      }
    }
    .handlerWrapper {
      position: relative;
      z-index: 2;
    }
    .btn {
      &-add {
        background: #fff;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 0;
        padding: 0;
        border: 1px solid #d4d5d9;
        width: 96px;
        min-height: 36px;
        line-height: 36px;
        height: 30px;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        color: $green;
        &:hover {
          box-shadow: 0 2px 8px #d4d5d9;
        }
      }
    }
    .controller {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.controller {
  background: #fff;

  border: 1px solid #d4d5d9;
  width: 96px;
  height: 36px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    outline: none !important;
    font-size: 22px;
    font-weight: 700;
    border: 0;
    background: transparent;
    color: $green;
    height: 30px;
    width: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.less {
      color: #bebfc5;
    }
  }
  .count {
    color: $green;
    font-size: 13px;
    font-weight: 700;
  }
}
.SideCartView {
  .sub-p {
    p {
      color: #3d4152;
      font-size: 15px;
      font-weight: 600;
      display: -ms-flexb;
      margin-bottom: 2px;
    }
    .extra-charges {
      font-size: 13px;
      font-weight: 300;
      color: #7e808c;
    }
  }
  .title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 18px;
  }
  .cart-item {
    display: flex;
    flex-wrap: wrap;

    padding: 10px 0;
    .detail {
      flex: 0 0 calc(100% - 150px);
      max-width: calc(100% - 150px);
      display: flex;
    }
    .handler {
      flex: 0 0 150px;
      max-width: 150px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .v-o-nv {
      height: 14px;
    }
    .name {
      flex-grow: 1;
      font-size: 14px;
      font-weight: 400;
      margin-left: 10px;
      margin-right: 5px;
    }
    .price {
      font-size: 13px;
      color: #535665;
    }
    .controller {
      width: 70px;
      height: 30px;
      button {
        height: 22px;
        width: 22px;
      }
    }
  }
}

.res-banner {
  background-color: #171a29;
  color: #fff;
  margin: 0 -15px;
  padding: 30px 15px;
  .title {
    font-weight: 300;
    font-size: 32px;
  }
  .dec {
    opacity: 0.7;
    font-size: 15px;
    p {
      margin-bottom: 8px;
    }
  }
  .rs-values {
    opacity: 0.7;
    font-weight: 400;
    font-size: 12px;
    margin-top: 5px;
  }
  .offer {
    border: 1px solid #bec0c8;
    position: relative;
    padding: 30px;
    .offer-label {
      font-size: 20px;
      font-weight: 500;
      background-color: #171a29;
      display: inline-block;
      padding-right: 10px;
      padding-bottom: 10px;
      text-transform: uppercase;
      position: absolute;
      top: -13px;
      left: -6px;
    }
    p {
      position: relative;
      padding-left: 30px;
      span {
        font-size: 1.5em;
        position: absolute;

        left: 0;
        top: 2px;
      }
    }
  }
}

.category-menu {
  @media (max-width: $tablet) {
    .btn {
      font-size: 18px;
    }
  }
}
