@font-face {
  font-family: "Proxima Nova";
  font-weight: 700;
  src: url(./fonts/ProximaNova/ProximaNova-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 600;
  src: url(./fonts/ProximaNova/ProximaNova-Semi-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 500;
  src: url(./fonts/ProximaNova/ProximaNova-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 400;
  src: url(./fonts/ProximaNova/ProximaNova-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Proxima Nova";
  font-weight: 300;
  src: url(./fonts/ProximaNova/ProximaNova-Light.ttf) format("truetype");
}
